<template>
  <div
    style="
      width: 100vw;
      height: 30px;
      padding-bottom: 5px;
      line-height: 30px;
      position: absolute;
      bottom: 0;
      text-align: center;
      color: #fff;
      text-decoration: underline;
    "
    @click="onClick"
  >
    {{ beian }}
  </div>
</template>
<script>
export default {
  name: "BeiAn",
  computed: {
    beian() {
      if (location.href.indexOf(".com.cn") >= 0) {
        return "浙ICP备2024095597号-1";
      } else {
        return "浙ICP备2024095597号-2";
      }
    },
  },
  methods: {
    onClick() {
        window.open("https://beian.miit.gov.cn/")
    },
  },
};
</script>
