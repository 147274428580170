import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/service/ConcatMe.vue')
  },
  {
    path: '/m/vXvE',
    name: 'm_vXvE',
    component: () => import('../views/service/ConcatMe.vue')
  },
  {
    path: '/m/vXvD',
    name: 'm_vXvD',
    component: () => import('../views/service/AudioPlay.vue')
  },
  {
    path: '/service/concatme',
    name: 'service_concatme',
    component: () => import('../views/service/ConcatMe.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
