<template>
  <div id="app">
    <router-view />
    <BeiAn />
  </div>
</template>
<script>
import BeiAn from "./views/components/BeiAn";
export default {
  name: "AppView",
  components: {
    BeiAn,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
}
</style>
